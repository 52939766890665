import React, { useMemo } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";

import { components } from "@crewos/shared";

const { Select } = components;

const statusSelect = [
  { label: "Active", value: true },
  { label: "Inactive", value: false },
];

const CustomerForm = ({ customerData, setCustomerData, width = 100 }) => {
  const defaultStatus = useMemo(
    () => statusSelect.find((status) => status.value === customerData.isActive),
    [customerData]
  );

  return (
    <Col className={`w-${width} px-0`}>
      <Row>
        <FormGroup className="col-6">
          <Label htmlFor="customerName">
            <span>Name</span>
            <span className="text-danger ms-1">*</span>
          </Label>
          <input
            id="customerName"
            className="form-control-redesign"
            maxLength="50"
            type="text"
            placeholder="Enter the Customer Name"
            name="customerName"
            value={customerData.customerName || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                customerName: e.target.value,
              })
            }
            required
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label htmlFor="customerNo">
            <span>Customer #</span>
            <span className="text-danger ms-1">*</span>
          </Label>
          <input
            id="customerNo"
            className="form-control-redesign"
            maxLength="255"
            type="text"
            placeholder="Enter the Customer #"
            name="customerNo"
            value={customerData.customerNo || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                customerNo: e.target.value,
              })
            }
            required
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6">
          <Label htmlFor="address">
            <span>Address</span>
          </Label>
          <input
            id="address"
            className="form-control-redesign"
            maxLength="50"
            type="text"
            placeholder="Enter the Address"
            name="address"
            value={customerData.address || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                address: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label htmlFor="city">
            <span>City</span>
          </Label>
          <input
            id="city"
            className="form-control-redesign"
            maxLength="50"
            type="text"
            placeholder="Enter the City"
            name="city"
            value={customerData.city || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                city: e.target.value,
              })
            }
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6">
          <Label htmlFor="state">
            <span>State</span>
          </Label>
          <input
            id="state"
            className="form-control-redesign"
            maxLength="25"
            type="text"
            placeholder="Enter the State"
            name="state"
            value={customerData.state || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                state: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label htmlFor="zipCode">
            <span>Zip Code</span>
          </Label>
          <input
            id="zipCode"
            className="form-control-redesign"
            maxLength="50"
            type="text"
            placeholder="Enter the Zip Code"
            name="zipCode"
            value={customerData.zipCode || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                zipCode: e.target.value,
              })
            }
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6">
          <Label htmlFor="countryCode">
            <span>Country Code</span>
          </Label>
          <input
            id="countryCode"
            className="form-control-redesign"
            maxLength="10"
            type="text"
            placeholder="Enter the Country Code"
            name="countryCode"
            value={customerData.countryCode || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                countryCode: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label htmlFor="phone">
            <span>Telephone Number</span>
          </Label>
          <input
            id="phone"
            className="form-control-redesign"
            maxLength="25"
            type="text"
            placeholder="Enter the Telephone Number"
            name="phone"
            value={customerData.phone || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                phone: e.target.value,
              })
            }
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6">
          <Label htmlFor="faxNo">
            <span>Fax Number</span>
          </Label>
          <input
            id="faxNo"
            className="form-control-redesign"
            maxLength="25"
            type="text"
            placeholder="Enter the Fax Number"
            name="faxNo"
            value={customerData.faxNo || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                faxNo: e.target.value,
              })
            }
          />
        </FormGroup>
        <FormGroup className="col-6">
          <Label htmlFor="email">
            <span>Email Address</span>
          </Label>
          <input
            id="email"
            className="form-control-redesign"
            maxLength="100"
            type="text"
            placeholder="Enter the Email Address"
            name="email"
            value={customerData.email || ""}
            onChange={(e) =>
              setCustomerData({
                ...customerData,
                email: e.target.value.trim(),
              })
            }
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup className="col-6">
          <Label htmlFor="statusSelect">
            <span>Status</span>
            <span className="text-danger ms-1">*</span>
          </Label>
          <Select
            name="statusSelect"
            inputId="statusSelect"
            inputValue={customerData.isActive}
            defaultValue={defaultStatus}
            options={statusSelect}
            onChange={(selected) => {
              setCustomerData({
                ...customerData,
                isActive: selected.value,
              });
            }}
            required
          />
        </FormGroup>
      </Row>
    </Col>
  );
};

export default CustomerForm;
