import React, { useEffect } from "react";
import { useParams } from "react-router";

import { components, data, helpers, sharedHelper } from "@crewos/shared";
import { getSelectedDateWorkOrder } from "@crewos/workorders";

import { useGetCrewWorkDays } from "./api/CrewWorkDays.hooks";
import { useGetCrews } from "./api/Crews.hooks";
import CrewWorkDayCrew from "./components/CrewWorkDayCrew";
import CrewWorkDayTimes from "./components/CrewWorkDayTimes";
import WorkOrderCrews from "./components/WorkOrderCrews";
import WorkOrderCrewsEquipments from "./components/WorkOrderCrewsEquipments";
import WorkOrderCrewsExpenses from "./components/WorkOrderCrewsExpenses";
import WorkOrderCrewsInspections from "./components/WorkOrderCrewsInspections";
import WorkOrderCrewsInspectionsTemplates from "./components/WorkOrderCrewsInspectionsTemplates";
import WorkOrderCrewsPerDiems from "./components/WorkOrderCrewsPerDiems";
import WorkOrderCrewsTimes from "./components/WorkOrderCrewsTimes";
import WorkPerformed from "./components/WorkPerformed";
import { crewHelper } from "./helpers/crewHelper";
import Settings from "./pages/settings/Settings";
import {
  ACTIONS as CREW_WORK_DAY_ACTIONS,
  CrewWorkDaysProvider,
  useCrewWorkDays,
} from "./providers/crewWorkDaysProvider";
import {
  ACTIONS as CREWS_ACTIONS,
  CrewsProvider,
  useCrews,
} from "./providers/crewsProvider";

const { createRoutes } = helpers;

const { Loader } = components;

const { SIDEBAR_ACTIONS, useWorkOrderDetails, WORK_ORDER_DETAILS_ACTIONS } =
  data;

const routes = [
  {
    sidebar: true,
    icon: null,
    name: "Crews Settings",
    path: `crews`,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "crews",
    isSetting: true,
    category: "Crews",
    component: Settings,
    order: 3,
  },
];

const CREWS = "CREWS";

const Routes = createRoutes(routes);

const CrewsData = ({ children }) => {
  const [workOrderDetails, setWorkOrderDetails] = useWorkOrderDetails();

  const { workOrderId } = useParams();

  const [crewsContext, setCrewsContext] = useCrews();
  const [crewWorkDaysContext, setCrewWorkDaysContext] = useCrewWorkDays();

  const {
    isLoading: isLoadingGetCrewWorkDays,
    get: getCrewWorkDays,
    data: crewWorkDaysData,
  } = useGetCrewWorkDays();

  useEffect(() => {
    getCrewWorkDays({ workOrderId });
  }, [getCrewWorkDays, crewWorkDaysContext.refresh, workOrderId]);

  useEffect(() => {
    if (crewWorkDaysData) {
      const workDays = Object.keys(crewWorkDaysContext.crewWorkDays).length;
      if (!workDays) {
        //calculate selected date only on first load
        const selectedDateWorkOrder = getSelectedDateWorkOrder(
          workOrderDetails.workOrder,
          crewWorkDaysData
        );
        setWorkOrderDetails({
          action: WORK_ORDER_DETAILS_ACTIONS.SELECT_DATE,
          payload: { date: selectedDateWorkOrder },
        });
      }
    }
  }, [
    crewWorkDaysData,
    crewWorkDaysContext.crewWorkDays,
    setWorkOrderDetails,
    workOrderDetails.workOrder,
  ]);

  useEffect(() => {
    if (crewWorkDaysData) {
      setCrewWorkDaysContext({
        action: CREW_WORK_DAY_ACTIONS.GET_CREW_WORK_DAYS_SUCCESS,
        payload: {
          crewWorkDays: crewWorkDaysData,
        },
      });
    }
  }, [
    crewWorkDaysData,
    setCrewWorkDaysContext,
    setWorkOrderDetails,
    workOrderDetails.workOrder,
  ]);

  const {
    isLoading: isLoadingGetCrews,
    get: getCrews,
    data: crews,
  } = useGetCrews();

  useEffect(() => {
    getCrews({ workOrderId });
  }, [getCrews, crewsContext.refresh, workOrderId]);

  useEffect(() => {
    if (crews) {
      setCrewsContext({
        action: CREWS_ACTIONS.GET_CREWS_SUCCESS,
        payload: { crews },
      });
    }
  }, [crews, setCrewsContext]);

  return isLoadingGetCrewWorkDays || isLoadingGetCrews ? <Loader /> : children;
};

const CrewsDataWrapper = (props) => (
  <CrewsProvider>
    <CrewWorkDaysProvider>
      <CrewsData {...props} />
    </CrewWorkDaysProvider>
  </CrewsProvider>
);

const setCrewSidebarRoutes = ({ setSidebarContext, userData }) => {
  if (!userData) {
    return;
  }

  const packages = userData.packages || [];
  const sidebarRoutes = routes.filter((route) => route.sidebar);

  const packageEnabled = sharedHelper.isPackageEnabled(packages, "crews");

  if (packageEnabled) {
    setSidebarContext({
      action: SIDEBAR_ACTIONS.SET_ROUTES,
      payload: { routes: sidebarRoutes, name: CREWS, order: 6 },
    });
  }
};

export {
  CREW_WORK_DAY_ACTIONS,
  crewHelper,
  Routes as CrewRoutes,
  CrewsDataWrapper as CrewsData,
  CrewWorkDayCrew,
  CrewWorkDaysProvider,
  CrewWorkDayTimes,
  setCrewSidebarRoutes,
  useCrews,
  useCrewWorkDays,
  useGetCrews,
  WorkOrderCrews,
  WorkOrderCrewsEquipments,
  WorkOrderCrewsExpenses,
  WorkOrderCrewsInspections,
  WorkOrderCrewsInspectionsTemplates,
  WorkOrderCrewsPerDiems,
  WorkOrderCrewsTimes,
  WorkPerformed,
};
