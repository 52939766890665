import React, {
  useReducer,
  createContext,
  useCallback,
  useContext,
} from "react";
import { v1 as uuidv1 } from "uuid";
import { deepEqual } from "../helpers/objects";
import { sharedHelper } from "@crewos/shared";

export const INSPECTION_STATUS = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  DELETE: "DELETE",
  WITHOUT_CHANGE: "WITHOUT_CHANGE",
};

export const ORIGIN_PAGE = {
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  VIEW: "VIEW",
};

const initialState = {
  template: {
    id: uuidv1(),
    name: "",
  },
  sections: {},
  groups: {},
  questions: {},
  options: {},
  activeTab: null,
  questionTypes: [],
  origin: ORIGIN_PAGE.CREATE,
  hasUnsavedChanges: false,
  errors: {},
};

const InspectionTemplateContext = createContext(initialState);

const inspectionTemplateReducer = (state, action) => {
  switch (action.type) {
    case "SET_ORIGIN":
      return {
        ...state,
        origin: action.payload,
      };
    case "UPDATE_TEMPLATE":
      return {
        ...state,
        template: action.payload,
        hasUnsavedChanges: true,
      };
    case "UPDATE_SECTIONS_ORDER":
      return {
        ...state,
        sections: action.payload,
        hasUnsavedChanges: true,
      };
    case "UPDATE_SECTION":
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.id]: action.payload,
        },
        hasUnsavedChanges: true,
      };
    case "REMOVE_SECTION":
      return {
        ...state,
        sections: action.payload,
        hasUnsavedChanges: true,
      };
    case "UPDATE_GROUPS_ORDER":
      return {
        ...state,
        groups: {
          ...state.groups,
          ...action.payload,
        },
        hasUnsavedChanges: true,
      };
    case "UPDATE_GROUP":
      return {
        ...state,
        groups: {
          ...state.groups,
          [action.payload.id]: action.payload,
        },
        hasUnsavedChanges: true,
      };
    case "REMOVE_GROUP":
      return {
        ...state,
        groups: action.payload,
        hasUnsavedChanges: true,
      };
    case "UPDATE_QUESTIONS_ORDER":
      return {
        ...state,
        questions: {
          ...state.questions,
          ...action.payload,
        },
        hasUnsavedChanges: true,
      };
    case "UPDATE_QUESTION":
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.id]: action.payload,
        },
        hasUnsavedChanges: true,
      };
    case "REMOVE_QUESTION":
      return {
        ...state,
        questions: action.payload,
        hasUnsavedChanges: true,
      };
    case "UPDATE_OPTIONS_ORDER":
      return {
        ...state,
        options: {
          ...state.options,
          ...action.payload,
        },
        hasUnsavedChanges: true,
      };
    case "UPDATE_OPTION":
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.id]: action.payload,
        },
        hasUnsavedChanges: true,
      };
    case "REMOVE_OPTION":
      return {
        ...state,
        options: action.payload,
        hasUnsavedChanges: true,
      };
    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload,
      };
    case "UPDATE_QUESTION_TYPES":
      return {
        ...state,
        questionTypes: action.payload,
      };
    case "SET_FULL_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "SET_HAS_UNSAVED_CHANGES":
      return {
        ...state,
        hasUnsavedChanges: action.payload,
      };
    case "SET_ERRORS":
      return {
        ...state,
        errors: action.payload,
      };
    case "UPDATE_GUIDANCE":
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...state.questions[action.payload.questionId],
            guidance: {
              ...state.questions[action.payload.questionId].guidance,
              ...action.payload.guidance,
              status:
                action.payload.guidance.status || INSPECTION_STATUS.UPDATE,
            },
          },
        },
        hasUnsavedChanges: true,
      };
    case "DELETE_GUIDANCE":
      return {
        ...state,
        questions: {
          ...state.questions,
          [action.payload.questionId]: {
            ...state.questions[action.payload.questionId],
            guidance: null,
          },
        },
        hasUnsavedChanges: true,
      };
    default:
      return state;
  }
};

const InspectionTemplateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(inspectionTemplateReducer, initialState);

  const updateTemplate = (template) => {
    removeErrors(template.id);
    dispatch({
      type: "UPDATE_TEMPLATE",
      payload: {
        ...template,
        status:
          state.origin === ORIGIN_PAGE.CREATE
            ? INSPECTION_STATUS.CREATE
            : INSPECTION_STATUS.UPDATE,
      },
    });
  };

  const updateSectionsOrder = (sections) => {
    const sectionsReduced = sections.reduce((acc, section, index) => {
      const newOrder = index + 1;
      let sectionStatus = state.origin;
      if (state.origin === ORIGIN_PAGE.UPDATE) {
        const oldOrder = section.order;
        sectionStatus =
          oldOrder === newOrder ? section.status : INSPECTION_STATUS.UPDATE;
      }
      acc[section.id] = {
        ...section,
        order: newOrder,
        status: sectionStatus,
      };
      return acc;
    }, {});
    dispatch({ type: "UPDATE_SECTIONS_ORDER", payload: sectionsReduced });
  };

  const updateSection = (section) => {
    let sectionStatus = INSPECTION_STATUS.CREATE;
    if (state.origin === ORIGIN_PAGE.UPDATE && section.id in state.sections) {
      const oldSection = state.sections[section.id];
      sectionStatus =
        oldSection.name === section.name
          ? oldSection.status
          : INSPECTION_STATUS.UPDATE;
    }
    removeErrors(section.id);
    dispatch({
      type: "UPDATE_SECTION",
      payload: { ...section, status: sectionStatus },
    });
  };

  const removeSection = (section) => {
    const groups = getGroups(section.id);
    groups.map((group) => removeGroup(group));

    const removedSection = state.sections[section.id];
    const sectionsMap = { ...state.sections };
    if (state.origin === ORIGIN_PAGE.UPDATE) {
      if (
        removedSection.status === INSPECTION_STATUS.UPDATE ||
        removedSection.status === INSPECTION_STATUS.WITHOUT_CHANGE
      ) {
        removedSection.status = INSPECTION_STATUS.DELETE;
        sectionsMap[section.id] = removedSection;
      } else if (removedSection.status === INSPECTION_STATUS.CREATE) {
        delete sectionsMap[section.id];
      } else if (removedSection.status === INSPECTION_STATUS.DELETE) {
        sectionsMap[section.id] = removedSection;
      }
    } else {
      delete sectionsMap[section.id];
    }
    const reorderedSections = Object.values(sectionsMap)
      .sort((a, b) => a.order - b.order)
      .filter((section) => section.status !== INSPECTION_STATUS.DELETE)
      .reduce((acc, section, index) => {
        acc[section.id] = { ...section, order: index + 1 };
        return acc;
      }, {});

    const sectionsData = {
      ...sectionsMap,
      ...reorderedSections,
    };
    if (state.activeTab === section.id) {
      setActiveTab(
        Object.values(sectionsData).find(
          (section) =>
            section.status !== INSPECTION_STATUS.DELETE && section.order === 1
        )?.id
      );
    }
    removeErrors(section.id);
    dispatch({
      type: "REMOVE_SECTION",
      payload: {
        ...sectionsMap,
        ...reorderedSections,
      },
    });
  };

  const updateGroupsOrder = (groups) => {
    const groupsReduced = groups.reduce((acc, group, index) => {
      const newOrder = index + 1;
      let groupStatus = state.origin;
      if (state.origin === ORIGIN_PAGE.UPDATE) {
        const oldOrder = group.order;
        groupStatus =
          oldOrder === newOrder ? group.status : INSPECTION_STATUS.UPDATE;
      }
      acc[group.id] = {
        ...group,
        order: index + 1,
        status: groupStatus,
      };
      return acc;
    }, {});
    dispatch({ type: "UPDATE_GROUPS_ORDER", payload: groupsReduced });
  };

  const updateGroup = (group) => {
    let groupStatus = INSPECTION_STATUS.CREATE;
    if (state.origin === ORIGIN_PAGE.UPDATE && group.id in state.groups) {
      const oldGroup = state.groups[group.id];
      groupStatus =
        oldGroup.name === group.name
          ? oldGroup.status
          : INSPECTION_STATUS.UPDATE;
    }
    removeErrors(group.id);
    dispatch({
      type: "UPDATE_GROUP",
      payload: { ...group, status: groupStatus },
    });
  };

  const removeGroup = (group) => {
    const questions = getQuestions(group.id);
    questions.map((question) => removeQuestion(question));

    const removedGroup = state.groups[group.id];
    const groupsMap = { ...state.groups };

    if (state.origin === ORIGIN_PAGE.UPDATE) {
      if (
        removedGroup.status === INSPECTION_STATUS.UPDATE ||
        removedGroup.status === INSPECTION_STATUS.WITHOUT_CHANGE ||
        removedGroup.status === INSPECTION_STATUS.DELETE
      ) {
        groupsMap[group.id].status = INSPECTION_STATUS.DELETE;
      } else if (removedGroup.status === INSPECTION_STATUS.CREATE) {
        delete groupsMap[group.id];
      }
    } else {
      delete groupsMap[group.id];
    }

    const reorderedGroups = Object.values(groupsMap)
      .sort((a, b) => a.order - b.order)
      .filter(
        (record) =>
          record.inspectionSectionId === group.inspectionSectionId &&
          record.status !== INSPECTION_STATUS.DELETE
      )
      .reduce((acc, group, index) => {
        acc[group.id] = { ...group, order: index + 1 };
        return acc;
      }, {});
    removeErrors(group.id);
    dispatch({
      type: "REMOVE_GROUP",
      payload: {
        ...groupsMap,
        ...reorderedGroups,
      },
    });
  };

  const updateQuestionsOrder = (questions) => {
    const questionsReduced = questions.reduce((acc, question, index) => {
      const newOrder = index + 1;
      let questionStatus = state.origin;
      if (state.origin === ORIGIN_PAGE.UPDATE) {
        const oldOrder = question.order;
        questionStatus =
          oldOrder === newOrder ? question.status : INSPECTION_STATUS.UPDATE;
      }
      acc[question.id] = {
        ...question,
        order: newOrder,
        status: questionStatus,
      };
      return acc;
    }, {});
    dispatch({ type: "UPDATE_QUESTIONS_ORDER", payload: questionsReduced });
  };

  const updateQuestion = (question) => {
    let questionStatus = INSPECTION_STATUS.CREATE;
    if (state.origin === ORIGIN_PAGE.UPDATE && question.id in state.questions) {
      const oldQuestion = state.questions[question.id];
      questionStatus = deepEqual(oldQuestion, question)
        ? oldQuestion.status
        : INSPECTION_STATUS.UPDATE;
    }

    if (!question.questionType.hasOptions) {
      const options = getOptions(question.id);
      options.map((option) => removeOption(option));
    }
    removeErrors(question.id);
    dispatch({
      type: "UPDATE_QUESTION",
      payload: { ...question, status: questionStatus },
    });
  };

  const removeQuestion = (question) => {
    const options = getOptions(question.id);
    options.map((option) => removeOption(option));

    const removedQuestion = state.questions[question.id];
    const questionsMap = { ...state.questions };

    if (state.origin === ORIGIN_PAGE.UPDATE) {
      if (
        removedQuestion.status === INSPECTION_STATUS.UPDATE ||
        removedQuestion.status === INSPECTION_STATUS.WITHOUT_CHANGE
      ) {
        removedQuestion.status = INSPECTION_STATUS.DELETE;
        questionsMap[question.id] = removedQuestion;
      } else if (removedQuestion.status === INSPECTION_STATUS.CREATE) {
        delete questionsMap[question.id];
      } else if (removedQuestion.status === INSPECTION_STATUS.DELETE) {
        questionsMap[question.id] = removedQuestion;
      }
    } else {
      delete questionsMap[question.id];
    }

    const reorderedGroups = Object.values(questionsMap)
      .sort((a, b) => a.order - b.order)
      .filter(
        (record) =>
          record.inspectionQuestionGroupId ===
          question.inspectionQuestionGroupId &&
          record.status !== INSPECTION_STATUS.DELETE
      )
      .reduce((acc, group, index) => {
        acc[group.id] = { ...group, order: index + 1 };
        return acc;
      }, {});
    removeErrors(question.id);
    dispatch({
      type: "REMOVE_QUESTION",
      payload: { ...questionsMap, ...reorderedGroups },
    });
  };

  const updateOptionsOrder = (options) => {
    const optionsReduced = options.reduce((acc, option, index) => {
      const newOrder = index + 1;
      let optionStatus = state.origin;
      if (state.origin === ORIGIN_PAGE.UPDATE) {
        const oldOrder = option.order;
        optionStatus =
          oldOrder === newOrder ? option.status : INSPECTION_STATUS.UPDATE;
      }
      acc[option.id] = {
        ...option,
        order: newOrder,
        status: optionStatus,
      };
      return acc;
    }, {});
    dispatch({ type: "UPDATE_OPTIONS_ORDER", payload: optionsReduced });
  };

  const updateOption = (option) => {
    let optionStatus = INSPECTION_STATUS.CREATE;
    if (state.origin === ORIGIN_PAGE.UPDATE && option.id in state.options) {
      const oldOption = state.options[option.id];
      optionStatus =
        oldOption.content === option.content
          ? oldOption.status
          : INSPECTION_STATUS.UPDATE;
    }
    removeErrors(option.inspectionQuestionId);
    dispatch({
      type: "UPDATE_OPTION",
      payload: { ...option, status: optionStatus },
    });
  };

  const removeOption = (option) => {
    const removedOption = state.options[option.id];
    const optionsMap = { ...state.options };

    if (state.origin === ORIGIN_PAGE.UPDATE) {
      if (
        removedOption.status === INSPECTION_STATUS.UPDATE ||
        removedOption.status === INSPECTION_STATUS.WITHOUT_CHANGE
      ) {
        removedOption.status = INSPECTION_STATUS.DELETE;
        optionsMap[option.id] = removedOption;
      } else if (removedOption.status === INSPECTION_STATUS.CREATE) {
        delete optionsMap[option.id];
      } else if (removedOption.status === INSPECTION_STATUS.DELETE) {
        optionsMap[option.id] = removedOption;
      }
    } else {
      delete optionsMap[option.id];
    }

    const reorderedOptions = Object.values(optionsMap)
      .sort((a, b) => a.order - b.order)
      .filter(
        (record) =>
          record.inspectionQuestionId === option.inspectionQuestionId &&
          record.status !== INSPECTION_STATUS.DELETE
      )
      .reduce((acc, option, index) => {
        acc[option.id] = { ...option, order: index + 1 };
        return acc;
      }, {});
    dispatch({
      type: "REMOVE_OPTION",
      payload: { ...optionsMap, ...reorderedOptions },
    });
  };

  const setActiveTab = (tab) => {
    dispatch({ type: "SET_ACTIVE_TAB", payload: tab });
  };

  const getSections = (includeAll = false) => {
    const sections = Object.values(state.sections).sort(
      (a, b) => a.order - b.order
    );

    if (includeAll) {
      return sections;
    }
    return sections.filter(
      (section) => section.status !== INSPECTION_STATUS.DELETE
    );
  };

  const getGroups = (sectionId, includeAll = false) => {
    return Object.values(state.groups)
      .filter(
        (group) =>
          group.inspectionSectionId === sectionId &&
          (includeAll || group.status !== INSPECTION_STATUS.DELETE)
      )
      .sort((a, b) => a.order - b.order);
  };

  const getQuestions = (groupId, includeAll = false) => {
    return Object.values(state.questions)
      .filter(
        (question) =>
          question.inspectionQuestionGroupId === groupId &&
          (includeAll || question.status !== INSPECTION_STATUS.DELETE)
      )
      .sort((a, b) => a.order - b.order);
  };

  const getOptions = (questionId, includeAll = false) => {
    return Object.values(state.options)
      .filter(
        (option) =>
          option.inspectionQuestionId === questionId &&
          (includeAll || option.status !== INSPECTION_STATUS.DELETE)
      )
      .sort((a, b) => a.order - b.order);
  };

  const copyOption = (option, keepOrder = false) => {
    const newOption = {
      ...option,
      id: uuidv1(),
      order: !keepOrder
        ? getOptions(option.inspectionQuestionId).length + 1
        : option.order,
      status: INSPECTION_STATUS.CREATE,
    };
    dispatch({ type: "UPDATE_OPTION", payload: newOption });
  };

  const copyQuestion = (question, keepOrder = false) => {
    const newQuestion = {
      ...question,
      id: uuidv1(),
      order: !keepOrder
        ? getQuestions(question.inspectionQuestionGroupId).length + 1
        : question.order,
      status: INSPECTION_STATUS.CREATE,
    };
    getOptions(question.id).map((option) =>
      copyOption({ ...option, inspectionQuestionId: newQuestion.id }, true)
    );
    dispatch({ type: "UPDATE_QUESTION", payload: newQuestion });
  };

  const copyGroup = (group, keepOrder = false) => {
    const newGroup = {
      ...group,
      id: uuidv1(),
      order: !keepOrder
        ? getGroups(group.inspectionSectionId).length + 1
        : group.order,
      status: INSPECTION_STATUS.CREATE,
    };
    getQuestions(group.id).map((question) =>
      copyQuestion(
        { ...question, inspectionQuestionGroupId: newGroup.id },
        true
      )
    );
    dispatch({ type: "UPDATE_GROUP", payload: newGroup });
  };

  const copySection = (section) => {
    const newSection = {
      ...section,
      id: uuidv1(),
      order: Object.keys(state.sections).length + 1,
      status: INSPECTION_STATUS.CREATE,
    };
    getGroups(section.id).map((group) =>
      copyGroup({ ...group, inspectionSectionId: newSection.id }, true)
    );
    dispatch({ type: "UPDATE_SECTION", payload: newSection });
  };

  const addOption = (questionId, isFirst = false) => {
    const order = isFirst ? 1 : getOptions(questionId).length + 1;
    const option = {
      id: uuidv1(),
      content: "",
      order,
      inspectionQuestionId: questionId,
      status: INSPECTION_STATUS.CREATE,
    };
    dispatch({ type: "UPDATE_OPTION", payload: option });
  };

  const addQuestion = (groupId, isFirst = false) => {
    const order = isFirst ? 1 : getQuestions(groupId).length + 1;
    const questionType =
      state.questionTypes.find(
        (questionType) => questionType.slug === "MULTIPLE_CHOICE_FIELD"
      ) || {};
    const question = {
      id: uuidv1(),
      content: "",
      order,
      inspectionQuestionTypeId: questionType.id,
      inspectionQuestionGroupId: groupId,
      questionType,
      status: INSPECTION_STATUS.CREATE,
    };
    dispatch({ type: "UPDATE_QUESTION", payload: question });
    addOption(question.id, true);
  };

  const addGroup = (sectionId, isFirst = false) => {
    const order = isFirst ? 1 : getGroups(sectionId).length + 1;
    const group = {
      id: uuidv1(),
      name: "",
      order,
      inspectionSectionId: sectionId,
      status: INSPECTION_STATUS.CREATE,
    };
    dispatch({ type: "UPDATE_GROUP", payload: group });
    addQuestion(group.id, true);
  };

  const addSection = () => {
    const order = Object.keys(state.sections).length + 1;
    const section = {
      id: uuidv1(),
      name: "",
      order,
      status: INSPECTION_STATUS.CREATE,
    };
    dispatch({ type: "UPDATE_SECTION", payload: section });
    addGroup(section.id, true);
    setActiveTab(section.id);
  };

  const updateQuestionTypes = (questionTypes) => {
    dispatch({ type: "UPDATE_QUESTION_TYPES", payload: questionTypes });
  };

  const getTotalQuestionsBySection = (sectionId) => {
    return Object.values(state.groups)
      .filter(
        (group) =>
          group.inspectionSectionId === sectionId &&
          group.status !== INSPECTION_STATUS.DELETE
      )
      .reduce((acc, group) => {
        return (
          acc +
          Object.values(state.questions).filter(
            (question) =>
              question.inspectionQuestionGroupId === group.id &&
              question.status !== INSPECTION_STATUS.DELETE
          ).length
        );
      }, 0);
  };

  const verifyQuestions = () => {
    const errors = {};

    Object.values(state.questions).forEach((question) => {
      if (question.status !== INSPECTION_STATUS.DELETE) {
        const questionErrors = [];
        if (!question.content) {
          questionErrors.push("Content is required");
        }
        if (!question.questionType || !question.inspectionQuestionTypeId) {
          questionErrors.push("Question type is required");
        }
        if (question.questionType.hasOptions) {
          const options = getOptions(question.id);
          const allOptionsEmpty = options.every(
            (option) => option.content === ""
          );
          if (allOptionsEmpty || options.length === 0) {
            questionErrors.push("At least one option is required");
          }
          const someOptionsEmpty = options.some(
            (option) => option.content === ""
          );
          if (someOptionsEmpty) {
            questionErrors.push("Some options are empty");
          }
          const optionsContent = options.map(({ content }) => content);
          const uniqueContent = new Set(optionsContent);
          if (uniqueContent.size !== optionsContent.length) {
            questionErrors.push("Options must be unique");
          }
        }
        if (
          question.guidance &&
          question.guidance.status !== INSPECTION_STATUS.DELETE
        ) {
          const isEmptyGuidanceText =
            (question.guidance.text || "").replaceAll(
              /(<[/]?[\w]+[/]?>)/g,
              ""
            ) === "";
          if (!question.guidance.attachment && isEmptyGuidanceText) {
            questionErrors.push("Guidance attachment or text is required");
          }
        }
        if (questionErrors.length > 0) {
          errors[question.id] = questionErrors;
        }
      }
    });
    return errors;
  };

  const verifyGroups = () => {
    const errors = {};

    Object.values(state.groups).forEach((group) => {
      if (group.status !== INSPECTION_STATUS.DELETE) {
        if (group.name === "") {
          errors[group.id] = ["Group name is required"];
        }
      }
    });
    return errors;
  };

  const verifySections = () => {
    const errors = {};

    Object.values(state.sections).forEach((section) => {
      if (section.status !== INSPECTION_STATUS.DELETE) {
        if (section.name === "") {
          errors[section.id] = ["Section name is required"];
        }
      }
    });
    return errors;
  };

  const verifyData = () => {
    const errors = {};

    if (state.template.name === "") {
      errors[state.template.id] = ["Template name is required"];
    }
    const sectionsErrors = verifySections();
    const groupsErrors = verifyGroups();
    const questionsErrors = verifyQuestions();
    const generalErrors = {
      ...errors,
      ...sectionsErrors,
      ...groupsErrors,
      ...questionsErrors,
    };
    dispatch({ type: "SET_ERRORS", payload: generalErrors });
    return generalErrors;
  };

  const uploadGuidanceAttachments = async () => {
    const guidanceAttachments = Object.values(state.questions).filter(
      (question) => question.guidance && question.guidance.attachment
    );
    const attachments = guidanceAttachments.filter(
      (question) => question.guidance.attachment instanceof File
    );
    const uploadedAttachments = new Map(
      attachments.map((question) => [question.id, question.guidance.attachment])
    );
    const files = Array.from(uploadedAttachments.values());
    try {
      const { urls } = await sharedHelper.uploadFile(files);
      for (const [questionId, file] of uploadedAttachments.entries()) {
        const guidance = state.questions[questionId].guidance;
        const url = urls.find((url) => url.endsWith(file.name));
        updateGuidance(questionId, { ...guidance, attachment: url });
      }
      return urls;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getData = async () => {
    const urls = await uploadGuidanceAttachments();
    if (!urls) {
      return {
        data: null,
        failedUploads: true,
      };
    }
    const fullData = {
      template: {
        ...state.template,
        sections: getSections(true).map((section) => {
          return {
            ...section,
            id:
              section.status === INSPECTION_STATUS.CREATE
                ? undefined
                : section.id,
            groups: getGroups(section.id, true).map((group) => {
              return {
                ...group,
                id:
                  group.status === INSPECTION_STATUS.CREATE
                    ? undefined
                    : group.id,
                questions: getQuestions(group.id, true).map((question) => {
                  const guidance = question.guidance
                    ? {
                      ...question.guidance,
                      attachment:
                        question.guidance.attachment instanceof File
                          ? urls.find((url) =>
                            url.endsWith(question.guidance.attachment.name)
                          )
                          : question.guidance.attachment,
                    }
                    : null;
                  return {
                    ...question,
                    guidance,
                    id:
                      question.status === INSPECTION_STATUS.CREATE
                        ? undefined
                        : question.id,
                    options: getOptions(question.id, true).map((option) => {
                      return {
                        ...option,
                        id:
                          option.status === INSPECTION_STATUS.CREATE
                            ? undefined
                            : option.id,
                      };
                    }),
                  };
                }),
              };
            }),
          };
        }),
      },
    };

    return {
      data: fullData,
    };
  };

  const setOrigin = (origin) => {
    dispatch({ type: "SET_ORIGIN", payload: origin });
  };

  const setFullData = (data) => {
    const { sections = [], ...template } = data;

    const fullState = {
      template,
      sections: {},
      groups: {},
      questions: {},
      options: {},
    };

    sections.forEach((section) => {
      const { groups = [], ...sectionData } = section;
      fullState.sections[sectionData.id] = {
        ...sectionData,
        status: INSPECTION_STATUS.WITHOUT_CHANGE,
      };

      groups.forEach((group) => {
        const { questions = [], ...groupData } = group;
        fullState.groups[groupData.id] = {
          ...groupData,
          status: INSPECTION_STATUS.WITHOUT_CHANGE,
        };

        questions.forEach((question) => {
          const { options = [], guidance, ...questionData } = question;
          fullState.questions[questionData.id] = {
            ...questionData,
            status: INSPECTION_STATUS.WITHOUT_CHANGE,
            guidance: guidance
              ? {
                ...guidance,
                status: INSPECTION_STATUS.WITHOUT_CHANGE,
              }
              : null,
          };

          options.forEach((option) => {
            fullState.options[option.id] = {
              ...option,
              status: INSPECTION_STATUS.WITHOUT_CHANGE,
            };
          });
        });
      });
    });

    dispatch({ type: "SET_FULL_DATA", payload: fullState });
    dispatch({ type: "SET_ORIGIN", payload: ORIGIN_PAGE.UPDATE });
    dispatch({
      type: "SET_ACTIVE_TAB",
      payload: Object.keys(fullState.sections)[0],
    });
    dispatch({ type: "SET_HAS_UNSAVED_CHANGES", payload: false });
  };

  const updateUnsavedChanges = (status) => {
    dispatch({ type: "SET_HAS_UNSAVED_CHANGES", payload: status });
  };

  const resetFullData = () => {
    dispatch({ type: "SET_FULL_DATA", payload: initialState });
  };

  const getErrors = useCallback(
    (id) => {
      return state.errors[id];
    },
    [state.errors]
  );

  const removeErrors = (id) => {
    const mergedErrors = { ...state.errors };
    delete mergedErrors[id];
    dispatch({ type: "SET_ERRORS", payload: mergedErrors });
  };

  const updateGuidance = (questionId, guidance) => {
    dispatch({ type: "UPDATE_GUIDANCE", payload: { questionId, guidance } });
  };

  const addGuidance = (questionId) => {
    const guidance = state.questions[questionId].guidance || {};
    if (guidance.status === INSPECTION_STATUS.DELETE) {
      updateGuidance(questionId, {
        ...guidance,
        status: INSPECTION_STATUS.UPDATE,
      });
    }
    if (!guidance.status) {
      updateGuidance(questionId, {
        ...guidance,
        status: INSPECTION_STATUS.CREATE,
      });
    }
  };

  const deleteGuidance = (questionId) => {
    const guidance = state.questions[questionId].guidance || {};
    if (guidance.status === INSPECTION_STATUS.CREATE || !guidance.status) {
      dispatch({ type: "DELETE_GUIDANCE", payload: { questionId } });
    } else {
      updateGuidance(questionId, {
        ...guidance,
        status: INSPECTION_STATUS.DELETE,
      });
    }
  };

  return (
    <InspectionTemplateContext.Provider
      value={{
        state,
        dispatch,
        updateTemplate,
        updateSection,
        removeSection,
        updateGroup,
        removeGroup,
        updateQuestion,
        removeQuestion,
        updateOption,
        removeOption,
        setActiveTab,
        getSections,
        getGroups,
        getQuestions,
        getOptions,
        updateSectionsOrder,
        updateGroupsOrder,
        updateQuestionsOrder,
        updateOptionsOrder,
        copyOption,
        copyQuestion,
        copyGroup,
        copySection,
        addOption,
        addQuestion,
        addGroup,
        addSection,
        updateQuestionTypes,
        getData,
        getTotalQuestionsBySection,
        setOrigin,
        setFullData,
        updateUnsavedChanges,
        resetFullData,
        getErrors,
        updateGuidance,
        addGuidance,
        deleteGuidance,
        verifyData,
        uploadGuidanceAttachments,
      }}
    >
      {children}
    </InspectionTemplateContext.Provider>
  );
};

const useInspectionTemplate = () => {
  const context = useContext(InspectionTemplateContext);
  if (context === undefined) {
    throw new Error(
      "useInspectionTemplate must be used within a InspectionTemplateProvider"
    );
  }
  return context;
};

export { InspectionTemplateProvider, useInspectionTemplate };
