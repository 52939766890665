import { useContext, useCallback } from "react";
import { v1 as uuidv1 } from "uuid";
import { ELEMENT_STATUS, FormTemplateContext } from "../index";
import ACTIONS from "../actions";
import useGroups from "./groups.hook";

const useSections = () => {
  const context = useContext(FormTemplateContext);
  const { state, dispatch, getGroups } = context;
  const { copyGroup, deleteGroups, addGroup } = useGroups();

  const setActiveTab = useCallback(
    (tab) => {
      dispatch({ type: ACTIONS.SET_ACTIVE_TAB, payload: tab });
    },
    [dispatch]
  );

  const addSection = useCallback(() => {
    const section = {
      id: uuidv1(),
      name: "",
      status: ELEMENT_STATUS.CREATE,
      order: Object.keys(state.sections).length + 1,
    };
    dispatch({ type: ACTIONS.ADD_SECTION, payload: section });
    setActiveTab(section.id);
    addGroup(section.id);
  }, [dispatch, state.sections, setActiveTab, addGroup]);

  const updateSectionName = useCallback(
    (id, name) => {
      const isNewSection =
        state.sections[id] &&
        state.sections[id].status === ELEMENT_STATUS.CREATE;
      const status = isNewSection
        ? state.sections[id].status
        : ELEMENT_STATUS.UPDATE;
      if (name === state.sections[id].name) {
        return;
      }
      dispatch({
        type: ACTIONS.UPDATE_SECTION_NAME,
        payload: { id, name, status },
      });
    },
    [dispatch, state.sections]
  );

  const updateSectionsOrder = useCallback(
    (sections) => {
      const sectionsReduced = sections.reduce((acc, section, index) => {
        acc[section.id] = {
          ...section,
          order: index + 1,
          status:
            section.status !== ELEMENT_STATUS.CREATE
              ? ELEMENT_STATUS.UPDATE
              : section.status,
        };
        return acc;
      }, {});
      dispatch({
        type: ACTIONS.UPDATE_SECTIONS_ORDER,
        payload: sectionsReduced,
      });
    },
    [dispatch]
  );

  const deleteSection = useCallback(
    (id) => {
      const sections = { ...state.sections };
      const isNewSection =
        sections[id] && sections[id].status === ELEMENT_STATUS.CREATE;
      if (isNewSection) {
        delete sections[id];
      } else {
        sections[id].status = ELEMENT_STATUS.DELETE;
      }

      const reorderedSections = Object.values(sections)
        .filter((section) => section.status !== ELEMENT_STATUS.DELETE)
        .sort((a, b) => a.order - b.order)
        .reduce((acc, section, index) => {
          acc[section.id] = {
            ...section,
            order: index + 1,
            status:
              section.status !== ELEMENT_STATUS.CREATE
                ? ELEMENT_STATUS.UPDATE
                : section.status,
          };
          return acc;
        }, {});

      const sectionsData = {
        ...sections,
        ...reorderedSections,
      };
      if (state.activeTab === id) {
        setActiveTab(
          Object.values(sectionsData).find(
            (section) =>
              section.status !== ELEMENT_STATUS.DELETE && section.order === 1
          )?.id
        );
      }
      deleteGroups(getGroups(id));
      dispatch({ type: ACTIONS.DELETE_SECTION, payload: sectionsData });
    },
    [
      dispatch,
      state.sections,
      deleteGroups,
      getGroups,
      setActiveTab,
      state.activeTab,
    ]
  );

  const copySection = useCallback(
    (section) => {
      const newSection = {
        ...section,
        id: uuidv1(),
        status: ELEMENT_STATUS.CREATE,
        order: Object.keys(state.sections).length + 1,
      };
      getGroups(section.id).map((group) =>
        copyGroup({ ...group, sectionId: newSection.id }, true)
      );
      dispatch({ type: ACTIONS.COPY_SECTION, payload: newSection });
    },
    [dispatch, copyGroup, state.sections, getGroups]
  );

  return {
    ...context,
    addSection,
    updateSectionName,
    updateSectionsOrder,
    deleteSection,
    copySection,
    setActiveTab,
  };
};

export default useSections;
