import React, { useEffect, useMemo, useState } from "react";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { toast } from "react-toastify";

import {
  components,
  api,
  sharedHelper,
  toastConfig,
  data,
} from "@crewos/shared";

import { syncApi } from "../../api/syncServices";

import { DebounceInput } from "react-debounce-input";

const { useAuth, AUTH_ACTIONS } = data;

const { useGetSettings, useUpdateSetting } = api;

const {
  Icon,
  Select,
  TooltipItem,
  AdvanceTable,
  ConfirmationModal,
  UploadPhotosModal,
  CustomCheckbox,
} = components;

const BRANDING_LOGO = "BRANDING_LOGO";
const LOGIN_LOGO = "LOGIN_LOGO";

const columns = (
  onChangeSettingStatus,
  onChangeSettingValue,
  workOrderStatus = []
) => [
  {
    accessor: "name",
    header: "Setting",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const value = rowData.row.name;
      return (
        <div data-value={value} className="d-flex align-items-center">
          {value}
          {rowData.row.description ? (
            <div className="ms-2">
              <TooltipItem
                id={`${rowData.row.id}-tooltip`}
                title={rowData.row.description}
              >
                <Icon name="info" className="text-primary" />
              </TooltipItem>
            </div>
          ) : null}
        </div>
      );
    },
  },
  {
    accessor: "status",
    header: "Status",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) =>
      !rowData.row.valueOnly ? (
        <CustomCheckbox
          checked={rowData.row.status}
          onClick={() =>
            onChangeSettingStatus(rowData.row, !rowData.row.status)
          }
          data-testid="status-switch"
          id={rowData.row.id}
          name={rowData.row.id}
          className="d-inline-flex align-items-center"
          small
        />
      ) : null,
  },
  {
    accessor: "value",
    header: "Value",
    disableFilter: true,
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { value, status } = rowData.row;
      if (!rowData.row.statusOnly) {
        const defaultValue = workOrderStatus.find(
          (status) => status.id === value
        );

        return rowData.row.valueType === "WORK_ORDER_STATUS" ? (
          <Select
            id="statusSelect"
            name="statusSelect"
            data-testid="status-select"
            defaultValue={defaultValue}
            options={workOrderStatus}
            onChange={(selected) =>
              onChangeSettingValue(rowData.row, selected.value)
            }
          />
        ) : (
          <DebounceInput
            debounceTimeout={900}
            disabled={rowData.row.valueReadOnly}
            placeholder={`${rowData.row.valuePlaceholder}...`}
            className="form-control"
            data-testid={`setting-value-${rowData.row.id}`}
            maxLength="50"
            min={0}
            type={rowData.row.valueType.toLowerCase()}
            value={value}
            onChange={(e) => onChangeSettingValue(rowData.row, e.target.value)}
          />
        );
      }
      return status ? value || "-" : "-";
    },
  },
];

const Settings = () => {
  const [authContext, setAuthContext] = useAuth();
  const [settings, setSettings] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState();
  const [updateSidebarLogoModal, setUpdateSidebarLogoModal] = useState();
  const [updateLoginLogoModal, setUpdateLoginLogoModal] = useState();

  const {
    data: settingsData,
    isLoading: isLoadingSettings,
    get: getSettings,
  } = useGetSettings();

  useEffect(() => {
    getSettings({
      packageId: null, //core
    });
  }, [getSettings, refresh]);

  useEffect(() => {
    if (settingsData) {
      setSettings(settingsData);
    }
  }, [settingsData]);

  const {
    data: updateSettingData,
    isLoading: isLoadingUpdateSetting,
    update: updateSettings,
  } = useUpdateSetting();

  useEffect(() => {
    if (updateSettingData) {
      setRefresh((prev) => !prev);
      sharedHelper.successToast(`Setting saved`);
    }
  }, [updateSettingData]);

  const onChangeSettingStatus = (setting, status) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updateSettings({
          ...setting,
          status,
        });
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting status",
      body: `Are you sure you want to change this setting status?`,
      confirmText: "Update",
    });
  };

  const onChangeSettingValue = (setting, value) => {
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updateSettings({
          ...setting,
          value,
        });
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Update setting value",
      body: `Are you sure you want to change this setting value?`,
      confirmText: "Update",
    });
  };

  const onSync = () => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        setConfirmationModal();
        toast.promise(
          async () => {
            try {
              const res = await syncApi.doSync();
              toast.success(
                `Success! Imported ${res.workOrders} work orders and ${res.customers} customers.`,
                {
                  ...toastConfig,
                  toastId: "success",
                }
              );
            } catch (error) {
              toast.error(`There was an error with your request`, {
                ...toastConfig,
                toastId: "error",
              });
            }
          },
          {
            pending: "Loading...",
          },
          toastConfig
        );
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Ben Parker Sync",
      body: `<span class="text-center">
          This will trigger a synchronization of Work Orders, & Customers data, new data will be added. Do you want to continue?
        </span>`,
      confirmColor: "warning",
    });
  };

  const onUploadLogo = (url, settingName) => {
    const setting = settings.find((setting) => setting.name === settingName);
    if (!setting) {
      return sharedHelper.errorToast("Setting not found");
    }
    setConfirmationModal({
      isOpen: true,
      confirmColor: "primary",
      onSubmit: async () => {
        await updateSettings({
          ...setting,
          value: url,
        });
        setUpdateSidebarLogoModal();
        setConfirmationModal();
        setAuthContext({
          action: AUTH_ACTIONS.REFRESH,
        });
      },
      onClose: () => {
        setConfirmationModal();
      },
      title: "Update logo",
      body: `Are you sure you want to change the logo?`,
      confirmText: "Update",
    });
  };

  const nonInternalSettings = settings.filter((setting) => !setting.isInternal);
  const statusOptions = useMemo(() => {
    return authContext.userData.workOrderStatus?.map((status) => ({
      label: status.name,
      value: status.id,
    }));
  }, [authContext.userData.workOrderStatus]);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Core Settings</h2>
            <small className="text-muted ms-2 pt-1">({settings.length})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <Button
              size="sm"
              className="me-3 d-flex align-items-center"
              color="primary"
              onClick={() => setUpdateSidebarLogoModal(true)}
            >
              <Icon name="upload" />
              <span className="ms-2">Sidebar/PDF Logo</span>
            </Button>
            <Button
              size="sm"
              className="me-3 d-flex align-items-center"
              color="primary"
              onClick={() => setUpdateLoginLogoModal(true)}
            >
              <Icon name="upload" />
              <span className="ms-2">Login Logo</span>
            </Button>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              data-testid="refresh-button"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
            <TooltipItem
              id="sync-tooltip"
              title="Import BR BP work orders and customers"
            >
              <Button
                size="sm"
                className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
                color="white"
                onClick={onSync}
              >
                <Icon name="download-cloud" />
              </Button>
            </TooltipItem>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <AdvanceTable
            columns={columns(
              onChangeSettingStatus,
              onChangeSettingValue,
              statusOptions
            )}
            data={nonInternalSettings}
            pageSize={nonInternalSettings.length || 1}
            isLoading={isLoadingSettings || isLoadingUpdateSetting}
            headerClassName="text-muted small"
            tableProps={{
              striped: true,
            }}
          />
        </CardBody>
      </Card>
      {confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : updateSidebarLogoModal ? (
        <UploadPhotosModal
          title={"Upload PDF & Sidebar Logo"}
          onClose={() => setUpdateSidebarLogoModal()}
          onSubmit={(data) => onUploadLogo(data.attachmentUrl, BRANDING_LOGO)}
        />
      ) : updateLoginLogoModal ? (
        <UploadPhotosModal
          title={"Upload Login Logo"}
          onClose={() => setUpdateLoginLogoModal()}
          onSubmit={(data) => onUploadLogo(data.attachmentUrl, LOGIN_LOGO)}
        />
      ) : null}
    </Container>
  );
};

export default Settings;
