import React, { useState, useCallback } from "react";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormGroup,
  ModalFooter,
  Button,
} from "reactstrap";

import { components } from "@crewos/shared";

const { TooltipItem, InputEmail, Icon, Select } = components;

const SIGN_OFF = {
  NOT_NEEDED: "NOT_NEEDED",
  ONLY_CREW_LEADER: "ONLY_CREW_LEADER",
  ALL_CREW: "ALL_CREW",
};

const SIGN_OFF_OPTIONS = [
  { value: SIGN_OFF.NOT_NEEDED, label: "Not needed" },
  { value: SIGN_OFF.ONLY_CREW_LEADER, label: "Only Crew Leader" },
  { value: SIGN_OFF.ALL_CREW, label: "All Crew" },
];

const ConfigurationModal = ({ isOpen, onClose, title, onSave }) => {
  const [templateRequired, setTemplateRequired] = useState(true);
  const [signOff, setSignOff] = useState(SIGN_OFF.NOT_NEEDED);
  const [emailNotifications, setEmailNotifications] = useState(true);
  const [emailNotificationsRecipients, setEmailNotificationsRecipients] =
    useState([]);

  const handleTemplateRequiredChange = useCallback((value) => {
    setTemplateRequired((prev) => !prev);
  }, []);

  const handleSignOffChange = useCallback((record) => {
    setSignOff(record);
  }, []);

  const handleEmailNotificationsChange = useCallback((value) => {
    setEmailNotifications((prev) => !prev);
  }, []);

  const handleEmailNotificationsRecipientsChange = useCallback((value) => {
    setEmailNotificationsRecipients(value);
  }, []);

  const handleSave = useCallback(() => {
    const data = {
      templateRequired,
      signOff,
      emailNotifications,
      emailNotificationsRecipients,
    };
    onSave(data);
  }, [
    templateRequired,
    signOff,
    emailNotifications,
    emailNotificationsRecipients,
    onSave,
  ]);

  return (
    <Modal isOpen={isOpen} size="md">
      <ModalHeader toggle={onClose} className="border-bottom-0 text-lg">
        {title || "Configurations"}
      </ModalHeader>
      <ModalBody>
        <div className="d-flex justify-content-between my-3 border-bottom pb-3">
          <div className="d-flex align-items-center gap-2">
            Template required for every Work Order
            <TooltipItem title="If this setting is ON, the Template will be automatically assigned to all the Work Orders, and will be marked as 'Required' for each Work Day of the WO.">
              <Icon
                name="info"
                className="text-primary"
                style={{ paddingBottom: "1px" }}
              />
            </TooltipItem>
          </div>
          <div>
            <FormGroup switch>
              <Input
                type="switch"
                role="switch"
                id="templateRequired"
                name="templateRequired"
                data-testid="templateRequired"
                defaultChecked={templateRequired}
                onChange={handleTemplateRequiredChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="d-flex justify-content-between my-3 border-bottom pb-3">
          <div className="col-7">Request sign off</div>
          <div className="col-5">
            <Select
              options={SIGN_OFF_OPTIONS}
              value={signOff}
              onChange={handleSignOffChange}
              data-testid="signOff"
            />
          </div>
        </div>
        <div className="d-flex justify-content-between my-3 pb-3">
          <div className="d-flex align-items-center gap-2">
            Email notifications
          </div>
          <div>
            <FormGroup switch>
              <Input
                type="switch"
                role="switch"
                id="emailNotifications"
                name="emailNotifications"
                data-testid="emailNotifications"
                defaultChecked={emailNotifications}
                onChange={handleEmailNotificationsChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="d-flex justify-content-between ps-4 pb-3">
          <div className="col-4">Send to</div>
          <div className="col-8">
            <InputEmail
              id="emailNotificationsRecipients"
              name="emailNotificationsRecipients"
              data-testid="emailNotificationsRecipients"
              defaultValue={emailNotificationsRecipients}
              onChange={handleEmailNotificationsRecipientsChange}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="justify-content-center">
        <Button color="primary" onClick={handleSave}>
          Save changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfigurationModal;
