import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DebounceInput } from "react-debounce-input";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import {
  useDeleteEquipmentOption,
  useGetEquipmentOptions,
} from "../api/EquipmentOptions.hooks";
import EquipmentModal from "../components/EquipmentModal";

const {
  Icon,
  Select,
  AdvanceTable,
  AdvanceTablePagination,
  ConfirmationModal,
} = components;

const columns = (onEdit, onDelete) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { name } = rowData.row;
      return name || "-";
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    headerProps: { className: "text-end", style: { width: 300 } },
    disableSortBy: true,
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          color="none"
          className="text-danger"
          data-testid="delete-button"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const Equipments = () => {
  const [equipmentOptions, setEquipmentOptions] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [showInactive, setShowInactive] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: equipmentOptionsData,
    isLoading: isLoadingEquipmentOptions,
    get: getEquipmentOptions,
  } = useGetEquipmentOptions();

  const { data: deleteEquipmentOptionData, deleteEquipmentOption } =
    useDeleteEquipmentOption();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getEquipmentOptions({
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
      isActive: showInactive ? "false" : "true",
    });
  }, [
    getEquipmentOptions,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
    showInactive,
  ]);

  useEffect(() => {
    if (equipmentOptionsData) {
      setEquipmentOptions(equipmentOptionsData);
    }
  }, [equipmentOptionsData, setEquipmentOptions]);

  useEffect(() => {
    if (deleteEquipmentOptionData) {
      sharedHelper.successToast(`Equipment deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteEquipmentOptionData, setRefresh]);

  const onEdit = (equipmentOption) => setEditModal(equipmentOption);

  const onDelete = (equipmentOption) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteEquipmentOption(equipmentOption.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Equipment",
      body: `Are you sure you want to delete ${equipmentOption.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  const activeSelect = useMemo(() => {
    return [
      { label: "Active", value: false },
      { label: "Inactive", value: true },
    ];
  }, []);

  const defaultSelect = useMemo(() => {
    return activeSelect.find((item) => item.value === showInactive);
  }, [showInactive, activeSelect]);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Equipment</h2>
            <small className="text-muted ms-2 pt-1">
              ({equipmentOptions.count})
            </small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <Select
                id="activeSelect"
                name="activeSelect"
                onChange={(selected) => setShowInactive(selected.value)}
                defaultValue={defaultSelect}
                options={activeSelect}
                placeholder="Filter status..."
              />
            </div>
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search for.."
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onEdit, onDelete)}
              data={equipmentOptions.data || []}
              pageSize={sizePerPage}
              sortable
              exportable
              exportName="equipments.csv"
              onSort={onSort}
              defaultSort={{
                sortBy,
                direction,
              }}
              isLoading={isLoadingEquipmentOptions}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={equipmentOptions.count}
            pageCount={equipmentOptions.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {editModal ? (
        <EquipmentModal
          equipmentOption={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <EquipmentModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Equipments;
