import {
  CreateTemplates,
  EditTemplates,
  ListTemplates,
  ViewTemplates,
} from "./pages";

import SafetyWrapper from "./components/SafetyWrapper";

import Settings from "./pages/settings/Settings";
import { helpers } from "@crewos/shared";

const { createRoutes } = helpers;

const routes = [
  {
    name: "Safety",
    icon: "shield",
    sidebar: true,
    path: "/safety",
    package: "safety",
    component: SafetyWrapper,
    scopes: ["IS_SUPER_ADMIN_USER", "IS_ADMIN_USER"],
    entries: [
      {
        icon: null,
        path: "templates/create",
        name: "New Template",
        component: CreateTemplates,
        sidebar: true,
      },
      {
        icon: null,
        path: "templates/edit/:id",
        name: "Edit Template",
        component: EditTemplates,
        sidebar: false,
      },
      {
        icon: null,
        path: "templates/view/:id",
        name: "View Template",
        component: ViewTemplates,
        sidebar: false,
      },
      {
        icon: null,
        path: "templates",
        name: "Templates",
        component: ListTemplates,
        sidebar: true,
      },
    ],
  },
  {
    sidebar: true,
    icon: null,
    name: "Safety Settings",
    path: `safety`,
    isSetting: true,
    scopes: ["IS_SUPER_ADMIN_USER"],
    package: "safety",
    category: "Safety",
    component: Settings,
    order: 1,
  },
];

const Routes = createRoutes(routes);

export { Routes, routes };
