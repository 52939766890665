import React, { useCallback, useEffect, useMemo, useState } from "react";

import { DebounceInput } from "react-debounce-input";
import { useNavigate } from "react-router-dom";

import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";

import { components, data, sharedHelper } from "@crewos/shared";

import { useDeleteCustomer, useGetCustomers } from "../api/Customers.hooks";
import CustomerModal from "../components/CustomerModal";

const { useAuth } = data;

const {
  Icon,
  Select,
  AdvanceTable,
  AdvanceTablePagination,
  ConfirmationModal,
} = components;

const columns = (onSeeDetails, onEdit, onDelete) => [
  {
    accessor: "customerName",
    header: "Name",
    headerProps: { className: "text-truncate", style: { width: 350 } },
    cellProps: {
      className: "text-truncate",
      style: { width: 350 },
    },
    Cell: (rowData) => {
      const { customerName } = rowData.row;
      return customerName || "-";
    },
  },
  {
    accessor: "customerNo",
    header: "Customer No.",
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { customerNo } = rowData.row;
      return customerNo || "-";
    },
  },
  {
    accessor: "locations",
    header: "Locations",
    disableSortBy: true,
    headerProps: { className: "text-truncate" },
    Cell: (rowData) => {
      const { locations } = rowData.row;
      return locations.length;
    },
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    disableSortBy: true,
    headerProps: { style: { width: 300 } },
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        <Button
          size="sm"
          color="primary"
          onClick={() => onSeeDetails(rowData.row.id)}
        >
          View Details
        </Button>
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        {onDelete ? (
          <Button
            size="sm"
            color="none"
            className="text-danger"
            data-testid="delete-button"
            onClick={() => onDelete(rowData.row)}
          >
            Delete
          </Button>
        ) : null}
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const IS_SUPER_ADMIN_USER = "IS_SUPER_ADMIN_USER";

const Customers = () => {
  const [authContext] = useAuth();
  const [customers, setCustomers] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("customerName");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const navigate = useNavigate();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [showInactive, setShowInactive] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: customersData,
    isLoading: isLoadingCustomers,
    get: getCustomers,
  } = useGetCustomers();

  const { data: deleteCustomerData, deleteCustomer } = useDeleteCustomer();

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    if (deleteCustomerData) {
      sharedHelper.successToast(`Customer deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteCustomerData, setRefresh]);

  const onEdit = (customer) => setEditModal(customer);

  const onDelete = (customer) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteCustomer(customer.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Customer",
      body: `Are you sure you want to delete ${customer.customerName}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  const onSeeDetails = (id) => navigate(`/customers/details/${id}`);

  useEffect(() => {
    getCustomers({
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
      isActive: showInactive ? "false" : "true",
    });
  }, [
    getCustomers,
    sortBy,
    direction,
    sizePerPage,
    page,
    search,
    refresh,
    showInactive,
  ]);

  useEffect(() => {
    if (customersData) {
      setCustomers(customersData);
    }
  }, [customersData, setCustomers]);

  const isSuperAdmin = sharedHelper.userMeetsRole(authContext.userData, [
    IS_SUPER_ADMIN_USER,
  ]);

  const activeSelect = useMemo(() => {
    return [
      { label: "Active", value: false },
      { label: "Inactive", value: true },
    ];
  }, []);

  const defaultActive = useMemo(() => {
    return activeSelect.find((option) => option.value === showInactive);
  }, [showInactive, activeSelect]);

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">Customers</h2>
            <small className="text-muted ms-2 pt-1">({customers.count})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <Select
                id="activeSelect"
                name="activeSelect"
                defaultValue={defaultActive}
                options={activeSelect}
                onChange={(selected) => {
                  setShowInactive(selected.value);
                }}
                placeholder="Filter status..."
              />
            </div>
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search for.."
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
            <div className="table-export-container me-3">
              <div id="table-export" />
            </div>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              isLoading={isLoadingCustomers}
              headerClassName="text-muted small"
              columns={columns(
                onSeeDetails,
                onEdit,
                isSuperAdmin ? onDelete : false
              )}
              data={customers.data || []}
              pageSize={sizePerPage}
              sortable
              onSort={onSort}
              exportable
              exportName="customers.csv"
              defaultSort={{
                sortBy,
                direction,
              }}
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={customers.count}
            pageCount={customers.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {editModal ? (
        <CustomerModal
          customer={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <CustomerModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default Customers;
