import React, { useCallback, useEffect, useState } from "react";

import { DebounceInput } from "react-debounce-input";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import { components, sharedHelper } from "@crewos/shared";

import { useDeleteUserRole, useGetUserRoles } from "../../api/UserRoles.hooks";

import UserRoleModal from "../../components/UserRoleModal";

const {
  AdvanceTable,
  AdvanceTablePagination,
  Icon,
  InformationModal,
  ConfirmationModal,
} = components;

const columns = (onSeeUsers, onEdit, onDelete) => [
  {
    accessor: "name",
    header: "Name",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => {
      const { name } = rowData.row;
      return name || "-";
    },
  },
  {
    accessor: "users",
    header: "# Users",
    type: "number",
    headerProps: { className: "text-truncate" },
    cellProps: {
      className: "text-truncate",
    },
    Cell: (rowData) => rowData.row.users.length,
  },
  {
    accessor: "id",
    header: "",
    disableFilter: true,
    headerProps: { style: { width: 300 } },
    disableSortBy: true,
    cellProps: {
      className: "text-end",
    },
    Cell: (rowData) => (
      <div className="d-flex align-items-center justify-content-end">
        <Button
          size="sm"
          color="primary"
          onClick={() => onSeeUsers(rowData.row)}
        >
          See Users
        </Button>
        <Button
          size="sm"
          color="none"
          className="mx-2 text-primary"
          onClick={() => onEdit(rowData.row)}
        >
          Edit
        </Button>
        <Button
          size="sm"
          className="text-danger"
          color="none"
          onClick={() => onDelete(rowData.row)}
        >
          Delete
        </Button>
      </div>
    ),
  },
];

const INIT_PAGE_SIZE = 15;
const INIT_PAGE = 0;

const UserRoles = () => {
  const [userRoles, setUserRoles] = useState({});
  const [sizePerPage, setSizePerPage] = useState(INIT_PAGE_SIZE);
  const [page, setPage] = useState(INIT_PAGE);
  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [direction, setDirection] = useState("asc");
  const [refresh, setRefresh] = useState();

  const [editModal, setEditModal] = useState();
  const [createModal, setCreateModal] = useState();

  const [informationModal, setInformationModal] = useState();

  const [confirmationModal, setConfirmationModal] = useState();

  const {
    data: userRolesData,
    isLoading: isLoadingUserRoles,
    get: getUserRoles,
  } = useGetUserRoles();

  const { data: deleteUserRoleData, deleteUserRole } = useDeleteUserRole();

  useEffect(() => {
    if (deleteUserRoleData) {
      sharedHelper.successToast(`User role deleted`);
      setRefresh((prev) => !prev);
    }
  }, [deleteUserRoleData, setRefresh]);

  const onSort = useCallback(
    ([data]) => {
      if (data) {
        if (data.sortBy === sortBy && data.direction === direction) {
          return;
        }
        setSortBy(data.sortBy);
        setDirection(data.direction);
        setPage(INIT_PAGE);
      } else {
        setSortBy(null);
        setDirection(null);
        setPage(INIT_PAGE);
      }
    },
    [setSortBy, setDirection, setPage, direction, sortBy]
  );

  useEffect(() => {
    getUserRoles({
      search,
      page,
      pageSize: sizePerPage,
      sortBy,
      direction,
    });
  }, [getUserRoles, sortBy, direction, sizePerPage, page, search, refresh]);

  useEffect(() => {
    if (userRolesData) {
      setUserRoles(userRolesData);
    }
  }, [userRolesData, setUserRoles]);

  const onEdit = (userRole) => setEditModal(userRole);

  const onDelete = (userRole) => {
    setConfirmationModal({
      isOpen: true,
      onSubmit: async () => {
        await deleteUserRole(userRole.id);
        setConfirmationModal();
      },
      onClose: () => {
        setConfirmationModal();
        setRefresh((prev) => !prev);
      },
      title: "Delete Role",
      body: `Are you sure you want to delete ${userRole.name}?`,
      confirmColor: "danger",
      confirmText: "Delete",
    });
  };

  const onSeeUsers = (userRole) => {
    setInformationModal({
      isOpen: true,
      title: `Role Users`,
      rawBody: true,
      size: "sm",
      onClose: () => setInformationModal(),
      body: (
        <ListGroup>
          {userRole.users.length ? (
            userRole.users.map((user, index) => (
              <ListGroupItem key={index}>
                {`${user.firstName} ${user.lastName}`}
              </ListGroupItem>
            ))
          ) : (
            <ListGroupItem>No users to show</ListGroupItem>
          )}
        </ListGroup>
      ),
    });
  };

  return (
    <Container fluid>
      <Card className="section-card">
        <CardHeader className="section-header">
          <div className="text-dark flex-grow-1 d-flex align-items-center">
            <h2 className="mb-0 ">User Roles</h2>
            <small className="text-muted ms-2 pt-1">({userRoles.count})</small>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="me-3">
              <DebounceInput
                className="search border-0 form-control"
                maxLength={50}
                minLength={1}
                debounceTimeout={900}
                placeholder="Search for.."
                value={search}
                onChange={(evt) => {
                  setSearch(evt.target.value);
                  setPage(INIT_PAGE);
                }}
              />
            </div>
            <Button
              size="sm"
              className="me-3 rounded-circle d-flex custom-rounded-button text-primary py-2"
              color="white"
              onClick={() => setRefresh((prev) => !prev)}
            >
              <Icon name="refresh-cw" />
            </Button>
            <Button color="primary" onClick={() => setCreateModal(true)}>
              Create
            </Button>
          </div>
        </CardHeader>
        <CardBody className="section-body">
          <div className="overflow-x-auto">
            <AdvanceTable
              columns={columns(onSeeUsers, onEdit, onDelete)}
              data={userRoles.data || []}
              pageSize={sizePerPage}
              sortable
              onSort={onSort}
              defaultSort={{
                sortBy,
                direction,
              }}
              isLoading={isLoadingUserRoles}
              headerClassName="text-muted small"
              tableProps={{
                striped: true,
              }}
            />
          </div>
          <AdvanceTablePagination
            totalCount={userRoles.count}
            pageCount={userRoles.totalPages}
            currentPage={page}
            onPageChange={(page) => setPage(page - 1)}
            pageSize={sizePerPage}
            onPageSizeChange={(sizePerPage) => {
              setSizePerPage(sizePerPage);
              setPage(INIT_PAGE);
            }}
          />
        </CardBody>
      </Card>
      {informationModal ? (
        <InformationModal {...informationModal} />
      ) : editModal ? (
        <UserRoleModal
          userRole={editModal}
          onClose={() => setEditModal(false)}
          onSubmit={() => {
            setEditModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : createModal ? (
        <UserRoleModal
          onClose={() => setCreateModal(false)}
          onSubmit={() => {
            setCreateModal(false);
            setRefresh((prev) => !prev);
          }}
        />
      ) : confirmationModal ? (
        <ConfirmationModal {...confirmationModal} />
      ) : null}
    </Container>
  );
};

export default UserRoles;
