import React, { useState } from "react";

import { Row, Card, CardBody, Button, Col } from "reactstrap";

import CrewModal from "./CrewModal";
import { ACTIONS, useCrews } from "../providers/crewsProvider";
import Crew from "./Crew";
import { data, sharedHelper } from "@crewos/shared";

const { useAuth } = data;

const SINGLE_CREW = "SINGLE_CREW";
const DIRECT_ASSIGNATION = "DIRECT_ASSIGNATION";

const CREWS_PACKAGE = "crews";

const WorkOrderCrews = () => {
  const [crewsContext, setCrewsContext] = useCrews();
  const [authContext] = useAuth();

  const [crewModal, setCrewModal] = useState();

  const isSingleCrewEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    SINGLE_CREW
  );

  const directAssignationEnabled = sharedHelper.isSettingEnabled(
    authContext.userData?.packages,
    CREWS_PACKAGE,
    DIRECT_ASSIGNATION
  );

  return (
    <Col className="col-12">
      <Row className="flex-grow-1 d-flex mx-0">
        <Card className="w-100 px-0 box-shadow-none mb-3">
          <CardBody className="d-flex justify-content-between align-items-center">
            <h2 className="mb-0">
              {directAssignationEnabled
                ? "Assignments"
                : isSingleCrewEnabled
                ? "Crew"
                : "Crews"}
            </h2>
            <div className="d-flex align-items-center">
              {!isSingleCrewEnabled || !crewsContext.crews?.length ? (
                <Button color="primary" onClick={() => setCrewModal(true)}>
                  Add {directAssignationEnabled ? "Assignment" : "Crew"}
                </Button>
              ) : null}
            </div>
          </CardBody>
        </Card>
        <Card
          className={`w-100 px-0 box-shadow-none mb-3 ${
            directAssignationEnabled ? "overflow-x-hidden" : ""
          }`}
        >
          {crewsContext.crews?.length ? (
            <CardBody
              className={`bg-transparent py-0 mb-3 ${
                directAssignationEnabled ? "d-flex flex-wrap px-0 mx-n2" : ""
              }`}
            >
              {crewsContext.crews.map((crew) => (
                <Crew key={crew.id} crew={crew} />
              ))}
            </CardBody>
          ) : (
            <CardBody>
              <div className="text-center text-muted py-1 small">
                No data to display
              </div>
            </CardBody>
          )}
        </Card>
      </Row>
      {crewModal ? (
        <CrewModal
          onClose={() => setCrewModal(false)}
          onSubmit={() => {
            setCrewModal(false);
            setCrewsContext({
              action: ACTIONS.REFRESH,
            });
          }}
        />
      ) : null}
    </Col>
  );
};

export default WorkOrderCrews;
